.side-bar-panel {
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 280px;
  border-right: 1px solid lightgray;
  box-shadow: 2px 0px 8px #0000001f;
  z-index: 1;
  .logo-container {
    text-align: center;
    .logo {
      object-fit: contain;
      max-width: 160px;
      margin-bottom: 20px;
    }
  }

  .side-bar-panel-item {
    background: #ececec;
    padding: 10px 20px;
    margin-bottom: 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in-out 300ms;
    &:hover {
      background: lightgray;
    }
  }
}
