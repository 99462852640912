.request-filter-container {
  padding: 20px;
  border-bottom: 1px solid black;
  flex: 1 1 auto;
  margin-bottom: 10px;
  box-shadow: inset 0 0 8px #ffffffa1;
  .result {
    text-align: right;
  }
}
