$font-size-base: 0.8rem;
@import "bootstrap/scss/bootstrap.scss";

body {
  margin: 0px;
}
body > #root > div {
  height: 100vh;
}

.date-picker-registration {
  height: 56px;
  width: 100%;
}

.date-picker-registration-poper {
  z-index: 100 !important;
}
.date-picker-registration-wrapper {
  width: 100%;
}

.editorClassName {
  background-color: rgba(211, 211, 211, 0.2);
  min-height: 400px;
  border-radius: 2px;
}
.DraftEditor-root {
  font-size: 24px;
}

// .toolbarClassName {
//   background-color: rgba(211, 211, 211, 0.2);
//   min-height: 400px;
//   border-radius: 2px;
//   color: red;
// }
