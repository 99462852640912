.login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: lightgray;
  align-items: center;
  .logo {
    width: 280px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  .login-container {
    min-width: 400px;
  }
}
