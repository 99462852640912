.sell-request-list-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  .filter-panel {
    display: flex;
    flex-direction: row;
    .result {
      width: 100px;
    }
  }
  .controller {
    background: #ffb300;
    padding: 10px;
  }
  .table {
    position: relative;
    margin: 0 10px;
  
    .img-thumbnail {
      max-height: 70px;
      transition: ease-in-out 200ms;
      cursor: pointer;
      &:hover {
        max-height: 100px;
      }
    }
  }
}

